import * as React from 'react'
import { PageProps } from 'gatsby'
import { UnknownRecord } from 'gatsby-source-prismic'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

interface PreviewPageProps extends UnknownRecord<PropertyKey> {
  isPreview?: boolean
}

const PreviewPage = ({ data }: PageProps<PreviewPageProps>) => {
  if (data?.isPreview === false) return <p>'Not a preview!'</p>

  return <p>Loading</p>
}

export default withPrismicPreviewResolver(PreviewPage)
